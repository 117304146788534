@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
