.text-l10 {
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0.5px;
}
.text-m10 {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.5px;
}
.text-l12 {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.text-r12 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.text-m12 {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.text-l14 {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.text-m14 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.text-m14-shrink {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 1000px) {
  .text-m14-shrink {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 550px) {
  .text-m14-shrink {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.5px;
  }
}
.text-r14 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.text-sb14 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.text-m16 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.text-sb16 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.text-sb18 {
  font-weight: 600;
  font-size: 18px;
}
.text-r22 {
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.5px;
}
.text-sb22 {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.5px;
}

.text-m28 {
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.5px;
}
.text-sb32 {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.5px;
}
