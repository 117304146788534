html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, Arial, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  overflow-wrap: break-word;
  box-sizing: border-box;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #2f3071;
  --toastify-color-info: #9798b8;
  --toastify-color-success: #00cd39;
  --toastify-color-warning: #fdaf01;
  --toastify-color-error: #e10101;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: #2f3071;
  --toastify-icon-color-success: #00cd39;
  --toastify-icon-color-warning: #fdaf01;
  --toastify-icon-color-error: #e10101;
}

.time-picker {
  border: 1px solid #dddee8;
  width: 100%;
  padding: 7px 10px;
  border-radius: 10px;
}
.react-time-picker__wrapper {
  border: none !important;
}
::-webkit-scrollbar {
  width: 5.5px;
  height: 6.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2f3071;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 0px !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}
.google-autocomplete::placeholder {
  opacity: 1 !important;
  color: white;
}
.google-autocomplete:-ms-input-placeholder {
  opacity: 1 !important;
}
.google-autocomplete::-ms-input-placeholder {
  opacity: 1 !important;
}
.google-autocomplete::-webkit-input-placeholder {
  opacity: 1 !important;
}

*:focus {
  outline: none;
}
.webcam-custom-class {
  max-height: unset;
}
.hide-mobile {
  display: block;
}

@media screen and (max-width: 550px) {
  .webcam-custom-class {
    max-height: 300px;
  }
  .hide-mobile {
    display: none;
  }
}

.react-time-picker--disabled {
  background: transparent !important;
  opacity: 0.5;
}

.settings-button-icon-positioning {
  position: absolute;
  top: 10px;
  right: 10px;
}
.span-2 {
  grid-column: span 2;
  justify-self: center;
}
@media screen and (max-width: 550px) {
  .settings-button-icon-positioning {
    right: 112px;
  }
}
@media screen and (max-width: 1000px) {
  .span-2 {
    grid-column: span 1;
  }
}
